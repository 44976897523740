import React from "react"
import cx from "classnames"
import Icon from "../Icon/Icon"
import { getTime } from "../../helpers/Playlist"

import "./PlaylistTrack.scss"

const PlaylistTrack = ({ track, hasBorder = false, className = "" }) => {
  return (
    <div className={cx("playlist-track", className, {
		'has-border': hasBorder
	})}>
      <img
        src={track.cover_art}
        alt="Martin Garrix"
        className="playlist-track__cover"
      />
      <div className="playlist-track__song">
        <p className="playlist-track__title">{track.title}</p>
        <p className="playlist-track__artist">{track.artist}</p>
      </div>
      <p className="playlist-track__duration">
        <Icon className="playlist-track__duration-icon" name="clock" />
        <span> {getTime(track.played_at)}</span>
      </p>
    </div>
  )
}

export default PlaylistTrack
