import React, { useState, useEffect } from "react"
import "./Playlist.scss"
import "./Select.scss"
import { useGuestApi } from "../../hooks/useApi"
import Title from "../Text/Title"
import BtnRounded from "../Buttons/BtnRounded"
import Loader from "../Loader/Loader"
import PlaylistTrack from "../PlaylistTrack/PlaylistTrack"

function Playlist({ stationName }) {
  const { getPlaylist, getStationPublicationBySlug } = useGuestApi()
  const [pagination, setPagination] = useState({
    current_page: 1,
    last_page: 1,
    per_page: 20,
  })
  const [radioId] = useState(9)
  const [tracks, setTracks] = useState([])
  const [currentStation, setCurrentStation] = useState(null);

  useEffect(fetchStation, [stationName])

  function fetchStation() {
    getStationPublicationBySlug(stationName).then((station) => {
      if(station) {
        setCurrentStation(station);
        fetchPlaylist(station);
      }
    })
  }

  function fetchPlaylist(station) {
    getPlaylist(
      station ? station.id : radioId,
      pagination
    ).then(response => {
      const newData = tracks.concat(response.data)
      setTracks(
        newData.filter(
          (v, i, a) => a.findIndex(v2 => v2.played_at === v.played_at) === i
        )
      )
      setPagination({
        current_page: response.meta.current_page,
        last_page: response.meta.last_page,
        per_page: response.per_page,
      })
    })
  }

  function loadMore() {
    pagination.current_page += 1
    fetchPlaylist(currentStation)
  }

  return (
    <div className="songlist playlist">
      <div className="playlist--head">
        <Title>Playlist</Title>
      </div>
      <div className="playlist__content">
        <div className="content--body">
          {!tracks.length && (
            <div className="content--loading">
              <Loader color={"#fe6727"} />
            </div>
          )}
          <ul className="content--list">
            {tracks.map((track, i) => (
              <li key={i}>
                <PlaylistTrack
                  track={track}
                  hasBorder={i < tracks.length - 1}
                />
              </li>
            ))}
          </ul>
        </div>

        <div className="content--pagination">
          <BtnRounded outline large onClick={loadMore}>
            {"Meer tracks laden..."}
          </BtnRounded>
        </div>
      </div>
    </div>
  )
}

export default Playlist
