import { DateTime } from "luxon"

export function generatePagination(lastPage) {
  if (lastPage) {
    const array = []
    for (let i = 1; i <= lastPage; i++) {
      array.push(i)
    }
    return array
  }
  return []
}

export function getTime(date) {
  return DateTime.fromSQL(date).toFormat("HH:mm")
}
