import React from "react"
import { Router } from "@reach/router"
import RadioPlaylist from "../components/RadioPlaylist/RadioPlaylist"

const Playlist = () => {
  return (
    <Router>
      <RadioPlaylist path="playlist/:station" />
    </Router>
  )
}

export default Playlist
