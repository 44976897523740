import React from "react"
import MainContent from "../../components/Layout/MainContent"
import withGuestLayout from "../../hoc/withGuestLayout"
import hero from "../../resources/images/hero-default.jpg"
import Playlist from "../Playlist/Playlist"
import "./RadioPlaylist.scss"

function RadioPlaylist({ station }) {
  return (
    <>
      <MainContent imageInternal={hero}>
        <Playlist stationName={station} />
      </MainContent>
    </>
  )
}

export default withGuestLayout(RadioPlaylist, {
  page: "Playlist",
})
